<template>
  <div>
    <a
      class="btn btn-theme btn-sm float-end"
      href="#"
      @click.prevent="action = 'add_region'; show_modal = true"
      ><i class="lni lni-plus me-2"></i>Add New</a
    >
    <h3>Regions</h3>
    <loading v-if="loading" />
    <div class="card mt-3" v-if="!loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Region</th>
              <th>Subregions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="regions.length == 0">
              <td colspan="3">There is no data to display</td>
            </tr>
            <tr v-for="(region, i) in regions" :key="`region-${i}`">
              <td>{{ i + 1 }}</td>
              <td width="30%">
                <span class="me-3">{{ region.name }}</span>
                <a href="#" class="mx-2" @click.prevent="action = 'add_sub'; edit = region; show_modal = true"><i class="lni lni-plus" title="Add Subregion"></i></a>
                <a href="#" class="mx-2" @click.prevent="action = 'edit_region'; edit = region; show_modal = true; name = region.name"><i class="lni lni-pencil-alt" title="Edit"></i></a>
                <a href="#" class="mx-2" @click.prevent="action = 'delete_item'; edit = region; show_modal = true"><i class="lni lni-trash-can" title="Delete"></i></a>
              </td>
              <td>
                <div class="row">
                  <div
                    class="col-md-6 py-2"
                    v-for="(sub, j) in region.children"
                    :key="`sub-${(i + 1) * j}`"
                  >
                    <span class="me-4">{{ sub.name }}</span>
                    <a href="#" class="mx-2" @click.prevent="action = 'edit_region'; edit = sub; show_modal = true; name = sub.name"><i class="lni lni-pencil-alt" title="Edit"></i></a>
                    <a href="#" class="mx-2" @click.prevent="action = 'delete_item'; edit = sub; show_modal = true"><i class="lni lni-trash-can" title="Delete"></i></a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <modal :show="show_modal" @close="show_modal = false">
      <template v-slot:header>
        {{ action == 'add_region' ? 'Add Region' : action == 'add_sub' ? `Add Subregion to ${edit.name}` : action == 'edit_region' ? `Edit Region`: 'Edit Subregion' }}
      </template>
      <form @submit.prevent="submit" v-if="action != 'delete_item'">
        <div class="form-group">
          <label>Name</label>
          <input type="text" v-model="name" required class="form-control" placeholder="Name">
        </div>
        <div class="form-group text-end">
          <button class="btn btn-primary"><i class="lni lni-save me-2"></i>Save</button>
        </div>
      </form>
      <form @submit.prevent="submit" v-if="action == 'delete_item'">
        <div class="alert alert-warning">
          This action will delete all associated sub regions and cannot be reversed.
        </div>
        <div class="form-group text-end">
          <button class="btn btn-primary"><i class="lni lni-save me-2"></i>Delete {{ edit.name }}</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      regions: [],
      loading: true,
      action: null,
      name: '',
      parent: {},
      edit: {},
      show_modal: false
    };
  },

  methods: {
    fetch() {
      this.axios.get("/api/v1/admin/regions").then((response) => {
        this.regions = response.data.regions;
        this.loading = false;
      });
    },

    submit() {
      let data = {
        name: this.name,
        action: this.action,
        parent: this.parent.id,
        edit_id: this.edit.id
      }

      this.axios.post('/api/v1/admin/regions', data).then(() => {
        this.fetch()
        this.$swal({
          title: "Success",
          text: "The regions have been updated",
          type: 'success'
        })

        this.show_modal = false
      })
    }
  },
};
</script>
